import React from 'react';
import './Modal.scss';

const Modal = ({ isVisible, title, content, footer, onClose, children }) =>
	!isVisible ? null : (
		<div className='modal'>
			<div className='modal-dialog'>
				<div className='modal-header'>
					<h3 className='modal-title'>{title}</h3>
					<span className='modal-close' onClick={onClose}>
						<i className='fa fa-times close-button'></i>
					</span>
				</div>
				<div className='modal-body'>
					<div className='modal-content'>{content || children}</div>
				</div>
				{footer && <div className='modal-footer'>{footer}</div>}
			</div>
		</div>
	);
export default Modal;
