import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import './ShopBanner.scss';

export default function ShopBanner ({
	title,
	imageSrc,
	description,
	imageAlt,
	backgroundStyle,
	list,
	listClassName = '',
	footer,
}) {
	return (
		<div className='shop-banner-container'>
			<div className='row'>
				<div className='col col-sm-12 col-md-6 col-lg-5' id='shop-left'>
					<ShopBannerImage
						imageAlt={imageAlt}
						imageSrc={imageSrc}
						backgroundStyle={backgroundStyle}
					/>
				</div>
				<div className='col col-sm-12 col-md-6 col-lg-7' id='shop-right'>
					<div className='shop-banner-title'>
						<h2>
							{title}
							<i className='fas fa-circle html-period'></i>
						</h2>
					</div>
					<div className="shop-banner-tagline">
						{!!description && (
							<div className="description">
								{description}
							</div>
						)}
						<ul className={`shwd-list ${!!listClassName && listClassName}`}>
							{list.map((item, i) => (
								<li key={i}>{item}</li>
							))}
						</ul>
                    </div>
					{footer}
				</div>
			</div>
		</div>
	);
}
ShopBanner.defaultProps = {
	title: 'I am the title',
	imageSrc: '',
	imageAlt: 'People working hard',
};

ShopBanner.propTypes = {
	title: PropTypes.string.isRequired,
	imageSrc: PropTypes.string.isRequired,
	imageAlt: PropTypes.string.isRequired,
};
const ShopBannerImage = ({ imageSrc, imageAlt, backgroundStyle }) => (
	<StaticQuery
		query={graphql`
			{
				background: allImageSharp(filter: { original: { src: { regex: "/" } } }) {
					edges {
						node {
							fluid(maxWidth: 1000) {
								originalName
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		`}
		render={data => {
			const myImg = data.background.edges.find(img => img.node.fluid.originalName === imageSrc);
			return (
				<BackgroundImage
					aria-label={imageAlt}
					role='img'
					className='shop-banner-image'
					fluid={myImg.node.fluid}
					style={{ position: 'static', ...backgroundStyle }}
				></BackgroundImage>
			);
		}}
	></StaticQuery>
);
