import React from 'react';
import './Button.scss';
const Button = ({ color, onClick, text, type, style, ...rest }) => (
	<React.Fragment>
		<button
			type={type || 'button'}
			role='button'
			className={`btn btn-swu-${color}`}
			onClick={onClick}
			style={{ ...style }}
			{...rest}
		>
			{text}
		</button>
	</React.Fragment>
);

export default Button;
