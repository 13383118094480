import React from 'react';
import './LoadingSpinner.scss';
const LoadingSpinner = () => (
	<React.Fragment>
		<div className='loading-spinner-container'>
			<div className='loading-spinner'></div>
			<p>Loading</p>
		</div>
	</React.Fragment>
);

export default LoadingSpinner;
