import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from '../Modal/Modal';
import CheckAvailabilityForm from './CheckAvailabilityForm';
import './Shop.scss';

const CheckAvailability = ({
	open,
  bookingUrl,
	handleClose,
}) => (
  <div className="override-modal">
    <Modal
      isVisible={open}
      title={<h4 className="modal-title no-margin">Check Availability</h4>}
      onClose={handleClose}
    >
      <div className="search-dropbox-modal-box">
        <Formik
          initialValues={{
            type: 'Antigen',
            date: null,
            email: '',
            time: new Date(),
          }}
          validationSchema={Yup.object().shape({
            type: Yup.string().required('Select test type'),
            email: Yup.string().required('Input your email address'),
          })}
          onSubmit={async (values) => {
            console.log(values);
            {/* await adminService.generateDiscount(token, {
              active: true,
              ...values,
            }).then((response) => {
              if (response.success) {
                setStatus({ severity: 'success', message: 'Discount successfully generated.' });
              } else {
                ToastsStore.error(response.error);
                setStatus({ severity: 'error', message: response.error });
              }
            })
            .catch((err) => setStatus({ severity: 'error', message: err.error })); */}
          }}
        >
          <Form>
            <CheckAvailabilityForm
              bookingUrl={bookingUrl}
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  </div>
);

export default CheckAvailability;
