import Badge from '@material-ui/core/Badge';
import { useCookie } from "react-use";
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@material-ui/core';
import ShopBanner from '../ShopBanner/ShopBanner';
import Basket from './Basket';
import SEO from '../SEO/SEO';
import Button from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import PRODUCTS from '../../helpers/products';
import productSvc from '../../services/product.service';
import getURLParams from '../../helpers/getURLParams';
import discountSvc from '../../services/discounts.service';
import ApprovedKits from './ApprovedKits';
import DoubleProduct from './DoubleProduct';
import QuoteFrom from '../QuoteFrom/QuoteFrom';
import PartnershipAd from '../PartnershipAd/PartnershipAd';
import HowTestWorks from './HowTestWorks';
import CheckAvailability from './CheckAvailability';
import './Shop.scss';

const Shop = ({ location }) => {
	const product = useRef(null);
	const locationArray = !!location ? location.split('/') : [''];
	const params = getURLParams(locationArray[locationArray.length - 1]);
	const utm_source = params['utm_source'];
	const [discount, setDiscount] = useState();
	const [value, updateCookie] = useCookie("utm_source");
	const [awcValue, awcUpdateCookie] = useCookie("awc");
	const awc = params['awc'] || awcValue;
	const discountCode = utm_source === 'ga' ? 'HQGAU30' : params['customer_discount'];
	const [open, setOpen] = useState(false);
	const [availabilityModal, setAvailabilityModal] = useState(false);
	const [approvedKitsModal, setApprovedKitsModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [basket, setBasket] = useState([]);
	const [products, setProducts] = useState([]);
	const totalAmount = basket.reduce((sum, { numberOfKits }) => sum + numberOfKits, 0);
	const antigenConsultation = products.find(({sku}) => PRODUCTS.antigenConsultation == sku);
	const covidTestCertificate = products.find(({sku}) => PRODUCTS.covidTestCertificate == sku);
	const isCertificateProductsActive = !!antigenConsultation && !!antigenConsultation.active && !!covidTestCertificate && !!covidTestCertificate.active;
	const openBasket = () => setOpen(true);
	const closeBasket = () => setOpen(false);
	const addDoubleProductToBasket = (items) => {
		const newItems = [...basket];
		items.map((item) => {
			const isItemAlreadyAdded = newItems.find(({ id }) => (id === item.id));
			if (!!isItemAlreadyAdded) {
				isItemAlreadyAdded.price += item.price;
				isItemAlreadyAdded.numberOfKits += item.numberOfKits;
				isItemAlreadyAdded.delivery = item.delivery;
			} else {
				newItems.push(item);
			}
			return item;
		});
		setBasket(newItems);
		openBasket();
	};
	const removeItem = (id) => {
		const newItems = [...basket].filter((item) => !id.includes(item.id));
		setBasket(newItems);
		if (!newItems.length) {
			closeBasket();
		}
	};
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					deploymentType
				}
			}
		}
	`);
	const config = data.site.siteMetadata.deploymentType === 'staging'
		? require('../../config/config.development')
		: require('../../config/config.production');

	const getData = async () => {
		setLoading(true);
		await productSvc.getProducts(config.jsonAPI, 'tags=dochq')
			.then(result => {
				if (result.success && result.products) {
					setProducts(result.products);
				} else {
		  		setProducts([]);
				}
			})
			.catch(() => {
				setProducts([]);
				console.log('error')
	  	});
		if (!!discountCode) {
			await discountSvc.getDiscountDetails(config.jsonAPI, discountCode)
			.then(result => {
				if (result.success && result.data && result.data.value && !!result.data.uses) {
					setDiscount(result.data);
				} else {
					console.log('Invalid discount code');
				}
			})
			.catch(() => {
				console.log('Invalid discount code');
			});
		}
		setLoading(false);
	}

	useEffect(() => {
		if (utm_source === 'ga') {
			updateCookie('ga');
		}
		if (awc) {
			awcUpdateCookie(awc);
		}
    getData();
  }, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			let trustpilotbox = document.getElementById('trustpilot_box');
			window.Trustpilot.loadFromElement(trustpilotbox);
			let trustpilotblock = document.getElementById('trustpilot_block');
			window.Trustpilot.loadFromElement(trustpilotblock);
		}
	}, [loading]);

	return (loading || !products.length) ? (
		<div className='row' style={{ justifyContent: 'center' }}>
			<LoadingSpinner />
		</div>
	) : (
		<div className="shop-page">
			<SEO
				title='Covid-19 Products'
				description='Covid-19 Products'
			/>
			{(!!basket.length && !open) && (
				<div className="shopping-cart-box">
					<IconButton aria-label="cart" onClick={openBasket}>
						<Badge badgeContent={totalAmount} color="secondary">
							<i className="fas fa-shopping-cart" />
						</Badge>
					</IconButton>
				</div>
			)}
			<ShopBanner
				imageAlt='People with mask'
				imageSrc='family-in-masks.png'
				title="Covid Travel Certificate"
				listClassName="shwd-list-partners"
				description={
					<div>
						Provide your own Antigen kit<br />
						and receive your Covid Travel Certificate.<br />
						Quick and easy online service.
						<div style={{ margin: '20px 0' }} id="trustpilot_box" className="trustpilot-widget trustpilot-widget-box" data-locale="en-GB" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="60a81450941eea000194948f" data-style-height="24px" data-style-width="100%" data-theme="light">
							<a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener"></a>
						</div>
						<div className="row center">
							<Button
								text="Buy now"
								color="pink"
								style={{ marginTop: 20 }}
								onClick={() => product.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
							/>
						</div>
					</div>
				}
				list={[
				]}
				backgroundStyle={{ backgroundPosition: 'top' }}
			/>
			<HowTestWorks />
			{isCertificateProductsActive && (
				<div ref={product}>
					<PartnershipAd
						imageTitle='Covid Fit to Travel Certificate'
						contentBody={
							<DoubleProduct
								sku1={PRODUCTS.antigenConsultation}
								sku2={PRODUCTS.covidTestCertificate}
								discount={discount}
								id1={antigenConsultation.id}
								price1={antigenConsultation.price}
								id2={covidTestCertificate.id}
								price2={covidTestCertificate.price}
								description={
									<p className="description">
										<b className="red-text">Do you already have your own test kit?</b><br />
										Book your online consultation to get your Fit to Travel certificate.<br />
										Receive your certificate via email in under 30 mins<br /><br />
									</p>
								}
								basket={basket}
								productName1="Video Consultation"
								productName2="Certificate"
								textBelowPrice2="(max 4 people per consultation)"
								addToBasket={addDoubleProductToBasket}
								listOfFeatures={
									<>
										<li>
											<span
												className="link"
												onClick={() => setApprovedKitsModal(true)}
											>
												Please check that your kit is EU approved <span className="underline">here</span> *
											</span>
										</li>
										<li>
											<span
												className="link"
												onClick={() => setAvailabilityModal(true)}
											>
												Check for appointments availability <span className="underline">here</span>
											</span>
										</li>
									</>
								}
								textAfterPrice={
									<p className="star-text">
										* Do not purchase this service if your kit is not on the EU approved kit list.
									</p>
								}
							/>
						}
						imageAlt='Tech'
						imageSrc='travel-vaccination.svg'
						side={'right'}
					/>
				</div>
			)}
			<QuoteFrom
				authorName='Madhur Srivastava'
				authorRole='CEO'
				authorCompany='DocHQ'
				authorImageSrc='madhur.jpg'
				quote={[
					'At DocHQ, we have issued over 45,000 Covid travel certificates across Europe to help people travel smoothly and on time.',
				]}
				backgroundColor='var(--doc-light-grey)'
			/>
			<div id="trustpilot_block" className="trustpilot-widget trustpilot-box" data-locale="en-GB" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="60a81450941eea000194948f" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
				<a href="https://uk.trustpilot.com/review/dochq.co.uk" target="_blank" rel="noopener"></a>
			</div>
			<ApprovedKits
				open={approvedKitsModal}
				jsonApi={config.jsonAPI}
				handleClose={() => setApprovedKitsModal(false)}
			/>
			<CheckAvailability
				open={availabilityModal}
				bookingUrl={config.bookingAPI}
				handleClose={() => setAvailabilityModal(false)}
			/>
			<Basket
				awc={awc}
				open={open}
				source="dochqde"
				discountCode={!!discount ? discountCode : ''}
				setBasket={setBasket}
				handleClose={closeBasket}
				basket={basket}
				discount={!!discount ? discount.value : 0}
				removeItem={removeItem}
			/>
		</div>
	);
};

export default Shop;
