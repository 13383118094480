const PRODUCTS = {
  testToRelease: 'SYN-UK-PCR-SNS-004',
  day8Pcr: 'SYN-UK-PCR-SNS-003',
  day2Pcr: 'SYN-UK-PCR-SNS-002',
  greenBundle: 'GREEN-BUNDLE',
  amberShortStay: 'SYN-UK-PCR-SNS-002',
  amberBundle: 'AMBER-BUNDLE',
  day8PcrSport: 'SPORT-UK-PCR-SNS-001',
  day2PcrSport: 'SPORT-UK-PCR-SNS-002',
  greenBundleSport: 'SPORT-UK-PCR-SNS-002',
  amberBundleSport: 'UEFA-AMBER-BUNDLE',
  amberShortStaySport: 'SPORT-UK-PCR-SNS-002',
  testToReleaseSport: 'SPORT-UK-PCR-SNS-003',
  fitToTravelPcr: 'SYN-UK-PCR-SNS-001',
  fitToTravelAntigen: 'FLX-UK-ANT-SNS-001',
  preDepartureTest: 'FLX-UK-ANT-SNS-002',
  fitToTravelNetherlands: 'SYN-UK-PCR-SNS-001,FLX-UK-ANT-SNS-001',
  amberListVaccinated: 'AMBER-V-BUNDLE',
  antigenConsultation: 'CONSULT-ANT',
  covidTestCertificate: 'COVID-TEST-CERTIFICATE',
};

export default PRODUCTS;
