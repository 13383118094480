import React from 'react';
import SEO from '../components/SEO/SEO';
import Shop from '../components/Shop/Shop';

const IndexPage = ({ location: { href }}) => (
	<React.Fragment>
		<SEO
      title='Home'
      description='An innovative healthcare platform which allows businesses to get their employees ‘back to work’ faster. Reducing sick days and improving employee wellness is a caring way to boost business performance and productivity.'
		/>
      <Shop location={href} />
	</React.Fragment>
);

export default IndexPage;
