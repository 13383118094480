import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Step from './Step';
import LinkButton from '../LinkButton/LinkButton';
import './Shop.scss';

const mobile = require('../../assets/icons/mobile.svg');
const drugs = require('../../assets/icons/drugs.svg');
const bag = require('../../assets/icons/bag.svg');

const HowTestWorks = () => {
  const antigenSteps = [
		'Book your online video consultation',
		'Perform the test',
		'Receive your results and certificate',
	];
  const icons = [
    mobile,
    drugs,
    bag,
  ]
  const antigenStepsAdditionalText = {
		1: 'with the guidance from our trained healthcare professional',
    2: 'via email in under 30 minutes',
  };

  return (
    <Grid container alignContent="center" justify="center" className="how-test-works-box">
      <Grid item xs={8}>
        <h3 style={{ textAlign: 'center', fontSize: 30 }}>
          How DocHQ test works
        </h3>
        <Grid container justify="space-between" alignContent="flex-start">
          {antigenSteps.map((label, i) => {
            let result = label;
            if (antigenStepsAdditionalText[i]) {
              result = (
                <p className="no-margin">
                  {label}
                  <br />
                  <Typography className="additional-text">
                    {antigenStepsAdditionalText[i]}
                  </Typography>
                </p>
              );
            }
            return (
              <Grid item xs={12} md={4}>
                <Step key={i} icon={icons[i]} number={i + 1}>{result}</Step>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={11} container justify="flex-end">
        <LinkButton
          newTab
          text="FAQ"
          linkSrc="/faq"
          color="green-outlined"
        />
      </Grid>
    </Grid>
  );
};

export default HowTestWorks;
