import React from 'react';
import './PartnershipAd.scss';

const PartnershipAd = ({
	imageSrc,
	imageAlt,
	imageTitle,
	imageSubtitle,
	contentTitle,
	contentBody,
	side,
	footer,
}) => {
	const image = imageSrc.includes('.svg')
		? require(`../../assets/icons/${imageSrc}`)
		: require(`../../assets/images/${imageSrc}`);
	return side && side === 'left' ? (
		<React.Fragment>
			<div className='partnership-ad-container'>
				<div className='partnership-ad'>
					<div className='row'>
						<div className='partnership-item col-sm-12 col-md-8'>
							<h3>{contentTitle}</h3>
							<div className='text'> {contentBody}</div>
						</div>
						<div className='partnership-item left col-sm-12 col-md-4 left-border'>
							<h5 className='image-title'>{imageTitle}</h5>
							<img src={image} alt={imageAlt} />
							<i>{imageSubtitle}</i>
						</div>
						<div>{footer}</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	) : (
		<React.Fragment>
			<div className='partnership-ad-container'>
				<div className='partnership-ad'>
					<div className='row'>
						<div className='partnership-item left col-sm-12 col-md-3 right-border'>
							<h5 className='image-title'>{imageTitle}</h5>
							<img src={image} alt={imageAlt} />
							<i>{imageSubtitle}</i>
						</div>
						<div className='partnership-item col-sm-12 col-md-8'>
							<h5>{contentTitle}</h5>
							<div className='text'> {contentBody}</div>
						</div>
						<div>{footer}</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default PartnershipAd;
