import React, { useState, useEffect } from 'react';
import productSvc from '../../services/product.service';
import Modal from '../Modal/Modal';
import './Shop.scss';

const ApprovedKits = ({
	open,
  jsonApi,
	handleClose,
}) => {
  const [kits, setKits] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getApprovedKits = async () => {
    setIsLoading(true);
		await productSvc.getApprovedKits(jsonApi)
			.then(result => {
				if (result.success && result.kits) {
					setKits([...result.kits].sort(({name: nameA}, {name: nameB}) => nameA < nameB ? -1 : nameA > nameB ? 1 : 0));
				} else {
          setKits([]);
        }
			})
			.catch(() => {
        setKits([]);
        console.log('error')
      });
    setIsLoading(false);
	};

  useEffect(() => {
    getApprovedKits();
  }, []);

  return (
    <div className="override-modal">
      <Modal
        isVisible={open}
        title={<h4 className="modal-title no-margin">Approved Test Kits</h4>}
        onClose={handleClose}
      >
        <div className="search-dropbox-modal-box">
          {!isLoading && (
            <>
              <p className="red-text"><b>
                * Please note: if your kit is not on the following list your appointment will be rejected we will not be able to issue you with a certificate.
              </b></p>
              <ul className='shwd-list'>
                {!!kits.length ? kits.map(({ name }, indx) => (
                  <li key={indx}>{name}</li>
                )) : (
                  <p>
                    No approved kits to show
                  </p>
                )}
              </ul>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ApprovedKits;
