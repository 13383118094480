import React, { useState } from 'react';
import { get } from 'lodash';
import Button from '../Button/Button';
import { Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './Shop.scss';

const DoubleProduct = ({
	id1,
  id2,
  sku1,
  sku2,
  basket,
	addToBasket,
	description,
	listOfFeatures,
	textAfterPrice,
	discount = null,
  productName1 = null,
  productName2 = null,
  price1: productPrice1,
  price2: productPrice2,
	deliveryQuantity = 0,
  textBelowPrice1 = null,
  textBelowPrice2 = null,
	isAnyActiveDropbox = false,
	quantityRestriction = false,
}) => {
	const [numberOfKits1, setNumberOfKits1] = useState(1);
	const price1 = !!discount ? productPrice1 - (productPrice1 * (discount.value / 100)) : productPrice1;
	const [delivery, setDelivery] = useState(deliveryQuantity === 0 ? '' : isAnyActiveDropbox ? 'drop' : 'royal');
  const returnPrice1 = (delivery === 'royal' ? (10 * numberOfKits1 * deliveryQuantity) : 0);
	const returnPriceWithDiscount1 = returnPrice1 * (!!discount ? 1 - (discount.value / 100) : 1);
	const totalPrice1 = (price1 * numberOfKits1) + (!!discount ? returnPriceWithDiscount1 : returnPrice1);
	const priceWithoutDiscount1 = (productPrice1 * numberOfKits1) + returnPrice1;
  const [numberOfKits2, setNumberOfKits2] = useState(1);
	const price2 = !!discount ? productPrice2 - (productPrice2 * (discount.value / 100)) : productPrice2;
  const returnPrice2 = (delivery === 'royal' ? (10 * numberOfKits2 * deliveryQuantity) : 0);
	const returnPriceWithDiscount2 = returnPrice2 * (!!discount ? 1 - (discount.value / 100) : 1);
	const totalPrice2 = (price2 * (numberOfKits2 || 1)) + (!!discount ? returnPriceWithDiscount2 : returnPrice2);
	const priceWithoutDiscount2 = (productPrice2 * numberOfKits2) + returnPrice2;
	const numberOfKits1InBasket = get(basket.find(({ id }) => id === id1), 'numberOfKits', 0);
  const products2CanBeAdded = 4 - get(basket.find(({ id }) => id === id2), 'numberOfKits', 0);

	return (
		<div className="tile-content">
			<div>
				{description}
			</div>
			<ul className="shwd-list">
				{listOfFeatures}
			</ul>
			<div className="buy-action-box" style={{ minHeight: 'unset', paddingBottom: 20 }}>
				{isAnyActiveDropbox && (
					<FormControl component="fieldset">
						<RadioGroup name="delivery" value={delivery} onChange={(event) => setDelivery(event.target.value)}>
							<FormControlLabel
								value="drop"
								control={<Radio />}
								label={<p className="no-margin"><b>Drop Off return - <span className="green-text">Included</span></b></p>}
							/>
							<FormControlLabel
								value="royal"
								control={<Radio />}
								label={<p className="no-margin"><b>Royal Mail return (+ £10.00 per kit)</b></p>}
							/>
						</RadioGroup>
					</FormControl>
				)}
				<div>
					<h4 className="no-margin" style={{ textAlign: 'left', fontSize: 20 }}>
						{productName1}
					</h4>
					<h3 style={{ textAlign: 'left', margin: '10px 0' }}>
						{!!discount ? (
							<>
								<span className="line-thought">
									£{priceWithoutDiscount1.toFixed(2)}
								</span>
								<span>
									&nbsp;&nbsp;£{totalPrice1.toFixed(2)}
								</span>
							</>
						) : (
							<>
								£{totalPrice1.toFixed(2)} <span className="green-available-text" style={{ fontSize: 16 }}>{textBelowPrice1}</span>
							</>
						)}
					</h3>
				</div>
			</div>
			<div className="buy-action-box" style={{ minHeight: 'unset' }}>
				<div>
					<h4 className="no-margin" style={{ textAlign: 'left', fontSize: 20  }}>
						{productName2}
					</h4>
					<h3 style={{ textAlign: 'left', margin: '10px 0' }}>
						{!!discount ? (
							<>
								<span className="line-thought">
									£{priceWithoutDiscount2.toFixed(2)}
								</span>
								<span>
									&nbsp;&nbsp;£{totalPrice2.toFixed(2)}
								</span>
							</>
						) : (
							<>
								£{totalPrice2.toFixed(2)} <span className="green-available-text" style={{ fontSize: 16 }}>{textBelowPrice2}</span>
							</>
						)}
					</h3>
				</div>
				{!!products2CanBeAdded && (
					<div className="row space-between mob-col">
						{!quantityRestriction && (
							<div className="row align-center">
								<Button
									text={<i className="fa fa-minus" aria-hidden="true"></i>}
									color='green add-btn'
									onClick={() => numberOfKits2 > 1 ? setNumberOfKits2(numberOfKits2 - 1) : null}
								/>
								<h4 style={{ margin: '0 10px' }}>
									{numberOfKits2}
								</h4>
								<Button
									text={<i className="fa fa-plus" aria-hidden="true"></i>}
									color='green add-btn'
									disabled={!products2CanBeAdded}
									onClick={() => products2CanBeAdded > numberOfKits2 ? setNumberOfKits2(numberOfKits2 + 1) : null}
								/>
							</div>
						)}
						<Button
							text='Add to basket'
							color='pink action-btn'
							onClick={() => {
								addToBasket([
									...(!!numberOfKits1InBasket ? [] : [{ sku: sku1, price: price1 * numberOfKits1, numberOfKits: numberOfKits1, title: productName1, id: id1, delivery, deliveryQuantity }]),
									...(numberOfKits2 > 0 ? [{ sku: sku2, price: price2 * numberOfKits2, numberOfKits: numberOfKits2, title: productName2, id: id2, delivery, deliveryQuantity }] : []),
								]),
								setNumberOfKits2(1);
							}}
						/>
					</div>
				)}
			</div>
			<div>
				{textAfterPrice}
			</div>
		</div>
	);
};

export default DoubleProduct;
