import axios from 'axios';

const bookingSvc = {
  async getSlotsByTime({ baseURL, date_time, date_time_to}) {
    return new Promise((resolve, reject) => {
      if (!!date_time || !!date_time_to) {
        axios({
          url: `${baseURL}?&service=video_gp_dochq&date_time=${date_time}&date_time_to=${date_time_to || ''}&language=DE`,
          method: 'get',
          headers: { 'Content-type': 'application,json' },
        })
          .then(response => {
            if (response.status === 200 || response.data.status === 'ok') {
              resolve({ success: true, appointments: response.data });
            } else if (response.status === 204) {
              resolve({ success: true, appointments: [] });
            } else if (response.status === 403) {
              reject({
                success: false,
                error: 'Unable to authenticate user.',
                authenticated: false,
              });
            } else {
              reject({
                success: false,
                error: 'An error occurred',
              });
            }
          })
          .catch(err => reject({ success: false, error: 'Server Error Occurred' }));
      } else {
        resolve({ success: false, error: 'Missing Details' });
      }
    });
  }
};

export default bookingSvc;
